import { get, post, destroy, put } from "./http";

const Api = {
  Home: {
    Announcement() {
      return get(`/announcement/latest`);
    },
    AnnouncementDetail(id) {
      return get(`/announcement/detail/${id}`);
    },
    GetCategoryListInfo() {
      return get("/slider/categoryList");
    }
  },
  Sliders: {
    Index(params) {
        return get(`/slider/index`, params);
    }
  },
  Topic: {
    List(params) {
      return get("/topic/index", params);
    },
    HotList() {
      return get("/topic/recommend_list");
    },
    Categories() {
      return get("/topic/category");
    },
    Detail(id) {
      return get("/topic/detail/" + id);
    },
  },
  Auth: {
    PasswordLogin(params) {
      return post(`/login/password`, params);
    },
    PasswordForget(params) {
      return post("/user/change_password", params);
    },
    PhoneRegister(params) {
      return post(`/register/phone`, params);
    },
    Logout() {
      return post(`/user/logout`);
    },
  },
  User: {
    Detail() {
      return get(`/user/detail`);
    },
    NicknameChange(params) {
      return post("/user/change_nickname", params);
    },
    UserPaper(params) {
      return get("/user/join_papers", params);
    },
    UserMockPaper(params) {
        return get("/user/join_mock_papers", params);
    },
    UserPractice(params) {
        return get("/user/join_practices", params);
    },
  },
  Other: {
    Config() {
        return get("/system/config");
    },
    Captcha(params) {
      return post(`/login/captcha?key=` + params.key);
    }
  },
  Exam: {
    CategoryList() {
      return get(`/paper/category`);
    },
    QuestionCategoryList() {
        return get(`/exam/category`);
    },
    PaperList(params) {
      return get(`/paper/list`, params);
    },
    PaperDetail(id) {
      return get("/paper/detail/" + id);
    },
    PaperJoin(id, params) {
      return get("/paper/" + id + "/join", params);
    },
    PaperJoinRecord(paperId, recordId) {
      return get("/paper/" + paperId + "/join/" + recordId);
    },
    PracticesList(params) {
      return get("/practice/list", params);
    },
    PracticeDetail(id) {
      return get("/practice/detail/" + id);
    },
    PracticePlay(id, chapterId) {
      return get("/practice/" + id + "/join/" + chapterId);
    },
    PracticeQuestionAnswerFill(id, chapterId, questionId, params) {
      return get("/practice/" + id + "/chapter/" + chapterId + "/question/" + questionId + "/answer", params);
    },
    PracticeQuestion(id, questionId) {
      return get("/practice/" + id + "/question/" + questionId);
    },
    PaperSubmitSingle(params) {
      return post("/paper/submit/single", params);
    },
    PaperSubmit(pid, rid) {
      return get("/paper/" + pid + "/submit/all/" + rid);
  },
    PaperRecord(id,params) {
      return get("/paper/record/" + id, params);
    },
    Search(params) {
      return get("/exam/search/question", params);
    },
    MockPaper: {
      List(params) {
        return get("/mock_paper/list", params);
      },
      Detail(id) {
        return get("/mock_paper/detail/" + id);
      },
      Join(id) {
        return get("/mock_paper/" + id + "/join");
      },
      JoinDetail(recordId) {
          return get("/mock_paper/record/" + recordId);
      },
      SubmitSingle(params) {
        return post("/mock_paper/submit/single", params);
      },
      SubmitAll(pid, rid) {
        return get("/mock_paper/" + pid + "/submit/all/" + rid);
      },
      Record(id,params) {
        return get("/mock_paper/join/record/" + id, params);
      },
    },
    Practice: {
      Collect(id) {
        return get("/paper/collect/question/" + id);
      },
      CollectionStatus(params) {
          return post(`/paper/collection/status/multi`, params);
      },
      CollectStatus(id) {
          return get(`/paper/collection/status/` + id);
      },
    },
    WrongBook: {
      Detail(params) {
          return get(`/exam/wrongBook`, params);
      },
      OrderMode(params) {
          return get("/exam/wrongBook/questions", params);
      },
      RemoveQuestion(id) {
          return destroy("/exam/wrongBook/delete/" + id);
      },
    },
    Collection: {
      Stats(params) {
          return get(`/exam/collection`, params);
      },
      OrderMode(params) {
          return get("/exam/collection/questions", params);
      },
    }
  }
};

export default Api;
