/**
 * (c) 才酝通
 */

let url = 'https://survey.qiasn.com/api';
// let url = 'http://localhost:8082/';

export default {
    url: url
}
