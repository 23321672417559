/**
 *  (c) 才酝通 
 */
export default {
  getToken() {
    return window.localStorage.getItem("education-h5-token");
  },
  saveToken(token) {
    window.localStorage.setItem("education-h5-token", token);
  },
  clearToken() {
    window.localStorage.removeItem("education-h5-token");
  },
  getTmpToken() {
    return window.localStorage.getItem("education-h5-tmp-token");
  },
  saveTmpToken(token) {
    window.localStorage.setItem("education-h5-tmp-token", token);
  },
  clearTmpToken() {
    window.localStorage.removeItem("education-h5-tmp-token");
  },
  saveLoginCode(code) {
    window.localStorage.setItem("login_code", code);
  },
  getLoginCode() {
    return window.localStorage.getItem("login_code");
  },
  clearLoginCode() {
    window.localStorage.removeItem("login_code");
  },
  getBizToken() {
    return window.localStorage.getItem("education-user-face-bizToken");
  },
  saveBizToken(token) {
    window.localStorage.setItem("education-user-face-bizToken", token);
  },
  clearBizToken() {
    window.localStorage.removeItem("education-user-face-bizToken");
  },
  getRuleId() {
    return window.localStorage.getItem("education-user-face-ruleId");
  },
  saveRuleId(token) {
    window.localStorage.setItem("education-user-face-ruleId", token);
  },
  clearRuleId() {
    window.localStorage.removeItem("education-user-face-ruleId");
  },
  saveSessionLoginCode(code) {
    window.sessionStorage.setItem("login_code:" + code, code);
  },
  getSessionLoginCode(code) {
    return window.sessionStorage.getItem("login_code:" + code);
  },
  removeTokenParams(url) {
    let parseUrl = new URL(url);
    let hash = parseUrl.hash;
    let querystring = hash.split("?");
    if (querystring.length <= 1) {
      return url;
    }

    let params = querystring[1].split("&");
    if (params.length === 0) {
      return url;
    }

    let data = [];
    for (let i = 0; i < params.length; i++) {
      if (
        params[i].indexOf("token=") === -1 &&
        params[i].indexOf("login_code=") === -1 &&
        params[i].indexOf("action=") === -1
      ) {
        data.push(params[i]);
      }
    }

    let newUrl =
      parseUrl.protocol +
      "//" +
      parseUrl.host +
      parseUrl.pathname +
      querystring[0];

    if (data.length > 0) {
      newUrl += "?" + data.join("&");
    }

    return newUrl;
  },
  isMobile() {
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag;
  },
  isChinaMobilePhone(phone) {
    return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(phone);
  },
  isWechatMini() {
    let ua = navigator.userAgent.toLowerCase();
    let isWeixin = ua.indexOf("micromessenger") != -1;
    if (isWeixin) {
      return true;
    } else {
      return false;
    }
  },
  getHMS(time) {
    const hour =
      parseInt(time / 3600) < 10
        ? "0" + parseInt(time / 3600)
        : parseInt(time / 3600);
    const min =
      parseInt((time % 3600) / 60) < 10
        ? "0" + parseInt((time % 3600) / 60)
        : parseInt((time % 3600) / 60);
    const sec =
      parseInt((time % 3600) % 60) < 10
        ? "0" + parseInt((time % 3600) % 60)
        : parseInt((time % 3600) % 60);
    return hour + ":" + min + ":" + sec;
  },
  getHost() {
    let host = window.location.protocol + "//" + window.location.host;
    let pathname = window.location.pathname;
    if (pathname && pathname !== "/") {
      host += pathname;
    }
    return host + "/#";
  },
  isWechat() {
    let ua = window.navigator.userAgent.toLowerCase();
    return /micromessenger/.test(ua);
  },
  getImg(html) {
    var regex = /src="([^"]*)"/gi;
    var ret = [];
    if (html != null) {
        var matches = html.match(regex);
        if (matches) {
            matches.forEach(function(match) {
                ret.push(match.replace('src="', '').replace('"', ''));
            });
        }
    }
    return ret;
},
  setAnswerContent(type, strAnswer, id) {
    let answerContent = '';
    let answerContentRows = '';
    let arrAnswer = [];
    let content = [];
    let contentRows = [];
    switch (type) {
        case 1:
            answerContent = 'option' + strAnswer;
            answerContentRows = 'option' + strAnswer;
            break;
        case 2:
            arrAnswer = strAnswer.split(',');
            content = [];
            contentRows = [];
            arrAnswer.forEach(v => {
                content.push('option' + v);
                contentRows.push('option' + v);
            });
            answerContent = content.join(',');
            answerContentRows = content
            break;
        case 3:
            if (strAnswer == '' || strAnswer == null){
                answerContent = '';
                answerContentRows = '';
            }else{
                answerContent = 'v2:::' + strAnswer;
                answerContentRows = JSON.parse(strAnswer)
            }
            break;
        case 4:
            answerContent = strAnswer;
            answerContentRows = strAnswer;
            break;
        case 5:
            answerContent = strAnswer;
            answerContentRows = parseInt(strAnswer);
            break;
        default:
            answerContent = '';
            answerContentRows = '';
            break;

    }
    return {
        answerContent: answerContent,
        answerContentRows: answerContentRows
    }
}
};
