<!-- (c) 才酝通 -->
<template>
  <div id="app">
    <!-- <router-view></router-view> -->
    <keep-alive>
        <router-view v-if="this.$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!this.$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast } from 'vant-green';

Vue.use(Toast);
import { mapState, mapMutations } from "vuex";

window.onload = function () {
  // 禁用双指放大
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  // 禁用双击放大
  let lastTouchEnd = 0
  document.addEventListener(
    'touchend',
    function (event) {
      const now = new Date().getTime()
      if (now - lastTouchEnd <= 300) {
        event.preventDefault()
      }
      lastTouchEnd = now
    },
    false
  )
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}

export default {
  async mounted() {
    Toast.loading({
      type: 'loading',
      forbidClick: true
    });
    await this.getConfig();
    await this.autoLogin();
  },
  computed: {
    ...mapState(["config"]),
  },
  methods: {
    ...mapMutations(["submitLogin", "logout", "setConfig"]),
    async autoLogin() {
      if (this.$route.name !== "Login") {
        let token = this.$utils.getToken();

        if (token) {
          await this.getUser();
        }
      }
      Toast.clear();
    },
    async getUser() {
      try {
        let res = await this.$api.User.Detail();
        this.submitLogin(res.data);
        Toast.clear();
      } catch (e) {
        Toast.clear();
        this.$message.error(e.message);
      }
    },
    async getConfig() {
      let res = await this.$api.Other.Config();
      this.setConfig(res.data);
      if (!this.$utils.isMobile()) {
        if (res.data.pcUrl !== "") {
          // window.location.href = res.data.pcUrl;
        }
      }
    }
  }
}
</script>
