var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-box"},[_c('div',{staticClass:"flex justify-center",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"thumb",style:({
        'border-radius': _vm.border + 'px',
        'background-image': 'url(' + _vm.value + ')',
        width: _vm.width + 'px',
        height: _vm.height + 'px',
        'background-position': 'center center',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
      })})])])
}
var staticRenderFns = []

export { render, staticRenderFns }